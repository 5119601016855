import { Dispatch } from "@reduxjs/toolkit";
import { requestWS } from "../../services/axios.config";


export const getBookingRecords = ({currentPage, pageSize, startDate, endDate, phone, status}: {currentPage: number, pageSize: number, startDate: string, endDate: string, phone: string, status: number}) => (dispatch: Dispatch) => {

	dispatch({
		type: "booking/loading",
		payload: true
	})

	const startDate2 = new Date(startDate); // Replace with your actual start date
	const year = startDate2.getFullYear();
	const month = String(startDate2.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day = String(startDate2.getDate()).padStart(2, '0');

	const endDate2 = new Date(endDate);

	const year2 = endDate2.getFullYear();
	const month2 = String(endDate2.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day2 = String(endDate2.getDate()).padStart(2, '0');

	const formattedStartDate = `${year}-${month}-${day}`;
	const formattedEndDate = `${year2}-${month2}-${day2}`;

	requestWS({
		method: "GET",
		url: "/avtosave/booked-records",
		params: {
			startDate: formattedStartDate,
			endDate: formattedEndDate,
			phone,
			page: currentPage,
			pageSize,
			status
		}
	}).then((response) => {
		dispatch({
			type: "booking/setRecords",
			payload: response.data.data
		})

	}).catch((error) => {
		dispatch({
			type: "booking/error",
			payload: error
		})
	})
		
}